@tailwind base;
@tailwind components;
@tailwind utilities;

body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", "Roboto", "Oxygen",
    "Ubuntu", "Cantarell", "Fira Sans", "Droid Sans", "Helvetica Neue",
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, "Courier New",
    monospace;
}
/* 
.lg\:overflow-x-scroll::-webkit-scrollbar {
  height: 8px;
}

.lg\:overflow-x-scroll::-webkit-scrollbar-thumb {
  background-color: #888;
  border-radius: 4px;
}

.lg\:overflow-x-scroll::-webkit-scrollbar-track {
  background-color: #f1f1f1;
}

.lg\:overflow-x-scroll {
  scrollbar-width: thin;
  scrollbar-color: #888 #f1f1f1;
} */
